import request from '@/utils/request';
import Export from '@/utils/export';
// 获取商户门店列表
export function getMerchantShopMenu(data) {
  return request('post', '/aps/merchant/authorizeMerCascadeVo', data)
}

// 财务统计豆腐块
export function getPayChannelCube(data) {
  return request('post', '/apm/statistic/flow/cube', data)
}

// 用户统计豆腐块
export function getUserlCube(data) {
  return request('post', '/apm/statistic/member/cube', data)
}

// 卡券销售统计
export function getCardList(data) {
  return request('post', '/apm/statistic/card/top', data)
}

// 房间销售统计
export function getRoomList(data) {
  return request('post', '/apm/statistic/room/cube', data)
}

// 流水分页查询
export function getFinancePageList(data) {
  return request('post', '/apm/statistic/flow/page', data)
}

// 收款方式项趋势分析
export function getPayChannelLine(data) {
  return request('post', '/apm/statistic/payChannelLine', data)
}

// 收入项趋势分析
export function getBusinessTypeLine(data) {
  return request('post', '/apm/statistic/businessTypeLine', data)
}

// 用户项趋势分析
export function getUserLine(data) {
  return request('post', '/apm/statistic/member/line', data)
}

// 用户统计折线图总量
export function getUserTotal(data) {
  return request('post', '/apm/statistic/member/total', data)
}

// 导出流水列表
export function getListExport(data,title) {
  return Export('post', '/apm/statistic/flow/excelExport', data, title)
}

// 流水总量查询
export function getFinanceTotal(data) {
  return request('post', '/apm/statistic/flow/total', data)
}