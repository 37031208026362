<template>
  <div class="user">
    <h1 class="head">
      <div class="font-18">{{ title }}</div>
      <div class="pointer mr15">当前累计用户：<span class="total">{{ total }}人</span></div>
    </h1>
    <section class="tofu">
      <ul class="tofu-ul">
        <li class="tofu-li" v-for="item in homeUserObj">
          <p class="count mb15"><span>{{ item.count }}</span>人</p>
          <p class="title">{{ item.title }}</p>
        </li>
      </ul>
      <div class="tofu-trend">
        <UserChart chartName="用户数趋势分析" :merchantShopInfo="merchantShopInfo"></UserChart>
      </div>
    </section>
  </div>
</template>

<script>
import { getUserlCube } from "@/api/home/home";
import { homeUserObj } from "@/db/objs";
import UserChart from "@/views/home/child/UserChart.vue"
export default {
  components: { UserChart },
  props: ['title', 'merchantShopInfo'],
  data() {
    return {
      homeUserObj,
      tableParams: {},
      total: ""
    }
  },
  methods: {
    init() {
      Object.assign(this.tableParams, this.merchantShopInfo);
      this.getUserlCube();
    },
    // 【请求】用户统计豆腐块
    getUserlCube() {
      let data = this.tableParams;
      getUserlCube(data).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            for (let i of homeUserObj) {
              i.count = res.data[i.name]
            };
            this.total = res.data.total;
          }
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.user {
  background: #fff;
  padding: .15rem;
  margin-bottom: .15rem;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .15rem;
}

.font-18 {
  font-size: .18rem;
}

.tofu {
  display: flex;
}

.tofu-ul {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin-right: .15rem;
  padding: .15rem;
  border-radius: .05rem;
  border: 1px solid rgba(238, 238, 238, 1);
}

.tofu-li {
  width: 32%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid rgba(238, 238, 238, 1);
  margin: .3rem 0;
}

.tofu-li:nth-child(3n) {
  border: 0;
}

.tofu-trend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: .15rem;
  position: relative;
  border-radius: .05rem;
  border: 1px solid rgba(238, 238, 238, 1);
}

.icons {
  font-size: .2rem;
  color: #EF9807;
}

.count {
  span {
    font-size: .38rem;
  }
}

.total {
  font-size: .24rem;
}
</style>