<template>
  <div class="page">
    <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="80%">
      <section class="table-search">
        <div class="search-L">
          <el-radio-group v-model="tableParams.searchDayType" class="mr15 mb15" @change="onTab">
            <el-radio-button v-for="item in ObjToOpt(timeFrameObj)" :key="item.value" :label="item.value">{{ item.label
            }}</el-radio-button>
          </el-radio-group>
          <el-date-picker v-model="dateToDate" class="mr15" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="onDate" :clearable="false">
          </el-date-picker>
          <el-select class="search-sel" v-model="tableParams.payChannel" placeholder="请选择收款方式" @change="reload" clearable>
            <el-option v-for="item in ObjToOpt(payChannelObj)" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select class="search-sel" v-model="tableParams.businessType" placeholder="请选择订单类型" @change="reload"
            clearable>
            <el-option v-for="item in ObjToOpt(businessTypeObj)" :key="item.value" :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-input class="search-inp" v-model="tableParams.kw" placeholder="输入昵称或手机号搜索" @input="kwChange" clearable />
        </div>
        <div class="search-R">
          <el-button type="primary" @click="onExport()">导出</el-button>
        </div>
      </section>
      <section class="mb15">
        统计范围：<span>{{ merchantShopInfo.merchantName }}商户&nbsp;{{ merchantShopInfo.shopName }}门店</span>
        <span>&nbsp;&nbsp;&nbsp;</span>
        合计收入：<span style="color: #009688;">{{ financeTotal.income || 0 }}</span>
        <span>&nbsp;&nbsp;&nbsp;</span>
        合计支出：<span style="color: #FF0000;">－{{ financeTotal.outgo || 0 }}</span>
      </section>
      <!-- Table表格 -->
      <el-table :data="tableData" border stripe :header-cell-style="{
        background: '#FAFAFA', color: '#000000'
      }">
        <el-table-column label="流水时间" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.enterTime || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="用户" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.nickName || "-" }}</span>
            <span>&nbsp;丨&nbsp;</span>
            <span>{{ scope.row.mobile || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="所属门店" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.shopName || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单类型" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.businessTypeName || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="付款方式" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.payChannelName || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="内容" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.description || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="金额" align="center">
          <template slot-scope="scope">
            <div :style="{ 'color': colorObj[scope.row.lendingDirection] }">
              <span v-if="scope.row.lendingDirection == 2">－</span>
              <span>{{ scope.row.amount }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- Table分页 -->
      <section class="table-footer">
        <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
          :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="currentPage" @current-change="onCurrentPage">
        </el-pagination>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import { getFinancePageList, getListExport, getFinanceTotal } from "@/api/home/home";
import { ObjToOpt } from "@/utils/utils";
import { timeFrameObj, payChannelObj, businessTypeObj } from "@/db/objs";
export default {
  props: ['merchantShopInfo'],
  data() {
    return {
      ObjToOpt,
      timeFrameObj,
      payChannelObj,
      businessTypeObj,
      colorObj: {
        1: "#009688", //绿
        2: "#FF0000", //红
      },
      // 编辑弹框
      editShow: false, //弹框开关
      editTitle: "",//弹框标题
      // table表格
      dateToDate: [],
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],//表格数据
      tableParams: {
        page: 1,
        size: 10,
        kw: "",//查询关键字
        payChannel: "",
        businessType: "",
        searchDayType: 1,
        searchStartTime: "",
        searchEndTime: "",
      },
      financeTotal: "",
    }
  },
  methods: {
    // 初始化
    onInit(type, title) {
      Object.assign(this.tableParams, this.merchantShopInfo);
      this.tableParams.payChannel = type ? Number(type) : type;
      this.editTitle = title;
      this.editShow = true;
      this.tableParams.searchDayType = 1;
      this.tableParams.businessType = "";
      this.dateToDate = [];
      this.tableParams.searchStartTime = "";
      this.tableParams.searchEndTime = "";
      this.tableParams.kw = "";
      this.getFinancePageList();
      this.getFinanceTotal();
    },

    // 【请求】表格数据
    getFinancePageList() {
      if (this.dateToDate) {
        this.tableParams.searchStartTime = this.dateToDate[0];
        this.tableParams.searchEndTime = this.dateToDate[1];
      } else {
        this.tableParams.searchStartTime = "";
        this.tableParams.searchEndTime = "";
      }
      let data = this.tableParams;
      getFinancePageList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】详情
    getFinanceTotal() {
      if (this.dateToDate) {
        this.tableParams.searchStartTime = this.dateToDate[0];
        this.tableParams.searchEndTime = this.dateToDate[1];
      } else {
        this.tableParams.searchStartTime = "";
        this.tableParams.searchEndTime = "";
      }
      let data = this.tableParams;
      getFinanceTotal(data).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.financeTotal = res.data;
          } else {
            this.financeTotal = "";
          }
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.financeTotal = "";
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getFinancePageList();
      this.getFinanceTotal();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getFinancePageList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】日期选择
    onDate() {
      this.tableParams.searchDayType = "";
      this.reload();
    },

    // 切换tab
    onTab(searchDayType) {
      this.tableParams.searchDayType = searchDayType;
      this.dateToDate = [];
      this.reload();
    },

    // 【监听】编辑
    onEdit(row) {
      this.editShow = true;
      this.editTitle = "编辑";
      this.params = JSON.parse(JSON.stringify(row));
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】导出
    onExport() {
      let data = this.tableParams;
      getListExport(data, this.editTitle);
    }

  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: .15rem;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  margin-bottom: .15rem;
  display: flex;
  justify-content: space-between;
}

.search-sel {
  width: 2.2rem;
  margin-right: .15rem;
}

.search-inp {
  width: 2.8rem;
}

.search-L {
  display: flex;
  flex-wrap: wrap;
}
</style>