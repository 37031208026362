<template>
  <div class="home">
    <div class="head">
      <el-cascader
        style="width: 220px"
        v-model="merchantShopId"
        :options="options"
        :props="{ checkStrictly: true }"
        clearable
        @change="onChange"
        placeholder="全部商户"
        ref="merchantShopId"
      ></el-cascader>
    </div>
    <FinanceModule
      title="财务统计"
      :merchantShopInfo="merchantShopInfo"
      ref="finaceModule"
    ></FinanceModule>
    <UserModule
      title="用户统计"
      :merchantShopInfo="merchantShopInfo"
      ref="userModule"
    ></UserModule>
  </div>
</template>

<script>
import { getMerchantShopMenu } from "@/api/home/home";
import FinanceModule from "@/views/home/FinanceModule.vue";
import UserModule from "@/views/home/UserModule.vue";
export default {
  components: { FinanceModule, UserModule },
  data() {
    return {
      options: [],
      merchantShopId: [],
      merchantShopInfo: {
        merchantId: "",
        shopId: "",
        merchantName: "全部",
        shopName: "全部",
        merchantIdSet: [],
        shopIdSet: [],
      },
    };
  },
  mounted() {
    this.getMerchantShopMenu();
    this.onChange();
  },
  methods: {
    // 【请求】获取商户门店列表
    getMerchantShopMenu() {
      let data = {};
      getMerchantShopMenu(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.options = res.data;
        }
      });
    },

    // 【监听】商户门店选择
    onChange() {
      let ref = this.$refs["merchantShopId"].getCheckedNodes();
      if (this.merchantShopId.length == 2) {
        this.merchantShopInfo = {
          merchantId: this.merchantShopId[0],
          shopId: this.merchantShopId[1],
          merchantName: ref[0].parent.label,
          shopName: ref[0].data.label,
          merchantIdSet: [this.merchantShopId[0]],
          shopIdSet: [this.merchantShopId[1]],
        };
      } else if (this.merchantShopId.length == 1) {
        this.merchantShopInfo = {
          merchantId: this.merchantShopId[0],
          shopId: "",
          merchantName: ref[0].label,
          shopName: "全部",
          merchantIdSet: [this.merchantShopId[0]],
          shopIdSet: [],
        };
      } else if (this.merchantShopId.length == 0) {
        this.merchantShopInfo = {
          merchantId: "",
          shopId: "",
          merchantName: "全部",
          shopName: "全部",
          merchantIdSet: [],
          shopIdSet: [],
        };
      }
      this.$nextTick(() => {
        this.$refs.finaceModule.init();
        this.$refs.userModule.init();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  background: #f0f2f5;
}

.head {
  padding: 0.15rem;
  border-bottom: 1px solid #eee;
  background: #fff;
  margin-bottom: 0.15rem;
}
</style>