<template>
  <div class="page" @click="onChart">
    <i class="iconfont icon-icon_qsfx icons"></i>
    <p class="column">趋</p>
    <p class="column">势</p>
    <p class="column">分</p>
    <p class="column">析</p>
    <!-- 编辑弹框 -->
    <el-dialog
      :title="chartName"
      :visible.sync="editShow"
      :close-on-click-modal="false"
      append-to-body
      width="1400px"
    >
      <!-- 查询 -->
      <section class="table-search">
        <div class="search-L">
          <el-radio-group
            v-model="tableParams.searchDayType"
            class="mr15"
            @change="onTab"
          >
            <el-radio-button
              v-for="item in ObjToOpt(timeFrameObj)"
              :key="item.value"
              :label="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
          <el-date-picker
            v-model="dateToDate"
            class="mr15"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="onDate"
            :clearable="false"
          >
          </el-date-picker>
        </div>
        <div class="search-R"></div>
      </section>
      <p class="mb-20">
        <b class="mr-20">所属商户：{{ merchantShopInfo.merchantName }}</b>
        <b class="mr-20">所属门店：{{ merchantShopInfo.shopName }}</b>
        <b class="mr-20">净总收入：{{ counts.totalCount }}</b>
        <b class="mr-20">位置下单净收入：{{ counts.roomCount }}</b>
        <b class="mr-20">卡券净收入：{{ counts.cardCount }}</b>
        <b class="mr-20">商品净收入：{{ counts.goodsCount }}</b>
      </p>
      <!-- 图表 -->
      <section class="chart">
        <div class="chart-L">
          <div id="myEchart" ref="myEchartA"></div>
        </div>
        <div class="chart-R">
          <div id="myEchart" ref="myEchartB"></div>
        </div>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import { getBusinessTypeLine, getPayChannelCube } from "@/api/home/home";
import { ObjToOpt } from "@/utils/utils";
import { timeFrameObj } from "@/db/objs";
import * as echarts from "echarts";
export default {
  props: ["chartName", "merchantShopInfo"],
  data() {
    return {
      ObjToOpt,
      timeFrameObj,
      // 编辑弹框
      editShow: false, //弹框开关
      editTitle: "", //弹框标题
      dateToDate: [],
      tableParams: {
        searchDayType: 1,
        searchStartTime: "",
        searchEndTime: "",
      },
      myEchartA: "",
      myEchartB: "",
      chartInfo: "",
      pieInfo: [],
      counts: "",
    };
  },
  computed: {
    // 日期
    dateList() {
      let arr = [];
      if (this.chartInfo) {
        for (let i of this.chartInfo) {
          arr = [...arr, i.date];
        }
      }
      return arr;
    },
    // 净总收入
    totalCount() {
      let arr = [];
      if (this.chartInfo) {
        for (let i of this.chartInfo) {
          arr = [...arr, i.totalCount];
        }
      }
      return arr;
    },
    // 位置下单净收入
    roomCount() {
      let arr = [];
      if (this.chartInfo) {
        for (let i of this.chartInfo) {
          arr = [...arr, i.roomCount];
        }
      }
      return arr;
    },
    // 卡券净收入
    cardCount() {
      let arr = [];
      if (this.chartInfo) {
        for (let i of this.chartInfo) {
          arr = [...arr, i.cardCount];
        }
      }
      return arr;
    },
    // 商品净收入
    goodsCount() {
      let arr = [];
      if (this.chartInfo) {
        for (let i of this.chartInfo) {
          arr = [...arr, i.goodsCount];
        }
      }
      return arr;
    },
  },
  methods: {
    // 【渲染】折线图
    myChartFnA() {
      if (this.myEchartA == "") {
        this.myEchartA = echarts.init(this.$refs.myEchartA);
      }
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.dateList,
        },
        tooltip: {
          trigger: "axis",
        },
        color: ["#EF9807", "#6DBE00", "#4870FF", "#B678F5"],
        yAxis: {
          type: "value",
        },
        legend: {
          data: ["净总收入", "位置下单净收入", "卡券净收入", "商品净收入"],
        },
        series: [
          {
            name: "净总收入",
            data: this.totalCount,
            type: "line",
            smooth: true,
          },
          {
            name: "位置下单净收入",
            data: this.roomCount,
            type: "line",
            smooth: true,
          },
          {
            name: "卡券净收入",
            data: this.cardCount,
            type: "line",
            smooth: true,
          },
          {
            name: "商品净收入",
            data: this.goodsCount,
            type: "line",
            smooth: true,
          },
        ],
      };
      this.myEchartA.setOption(option);
    },

    // 【渲染】饼图
    myChartFnB() {
      if (this.myEchartB == "") {
        this.myEchartB = echarts.init(this.$refs.myEchartB);
      }
      let option = {
        color: ["#6DBE00", "#4870FF", "#B678F5"],
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.pieInfo,
          },
        ],
      };
      this.myEchartB.setOption(option);
    },

    // 【请求】收款方式项趋势分析
    getBusinessTypeLine() {
      if (this.dateToDate) {
        this.tableParams.searchStartTime = this.dateToDate[0];
        this.tableParams.searchEndTime = this.dateToDate[1];
      } else {
        this.tableParams.searchStartTime = "";
        this.tableParams.searchEndTime = "";
      }
      let data = this.tableParams;
      getBusinessTypeLine(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.chartInfo = res.data;
          this.$nextTick(() => {
            this.myChartFnA();
          });
        }
      });
    },

    // 【请求】财务统计豆腐块
    getPayChannelCube() {
      if (this.dateToDate) {
        this.tableParams.searchStartTime = this.dateToDate[0];
        this.tableParams.searchEndTime = this.dateToDate[1];
      } else {
        this.tableParams.searchStartTime = "";
        this.tableParams.searchEndTime = "";
      }
      let data = this.tableParams;
      getPayChannelCube(data).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.pieInfo = [
              {
                name: "位置下单净收入",
                value: res.data.roomCount,
              },
              {
                name: "卡券净收入",
                value: res.data.cardCount,
              },
              {
                name: "商品净收入",
                value: res.data.goodsCount,
              },
            ];
            this.counts = res.data;
            this.$nextTick(() => {
              this.myChartFnB();
            });
          }
        }
      });
    },

    //【监听】打开图表
    onChart() {
      Object.assign(this.tableParams, this.merchantShopInfo);
      this.editShow = true;
      this.dateToDate = [];
      this.tableParams.searchDayType = 1;
      this.getBusinessTypeLine();
      this.getPayChannelCube();
    },

    // 【监听】时段选择
    onTab(searchDayType) {
      this.tableParams.searchDayType = searchDayType;
      this.dateToDate = [];
      this.getBusinessTypeLine();
      this.getPayChannelCube();
    },

    // 【监听】日期选择
    onDate() {
      this.tableParams.searchDayType = "";
      this.getBusinessTypeLine();
      this.getPayChannelCube();
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding: 0.15rem 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icons {
  font-size: 0.2rem;
  color: #4870ff;
  margin-bottom: 0.15rem;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.table-search {
  margin-bottom: 0.15rem;
  display: flex;
  justify-content: space-between;
}

.search-L {
  display: flex;
}

.chart {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.chart-L {
  width: 60%;
}

.chart-R {
  flex: 1;
}

#myEchart {
  width: 100%;
  height: 4rem;
}

.mb-20{
  margin-bottom: 0.15rem;
}
.mr-20 {
  margin-right: 24px;
}
</style>